.editable-order {
  .ant-form-item-explain-error {
    display: none !important;
  }
  .ant-input-status-error {
    border: 1px solid #ff4d4f !important;
  }
}

.event-master-menus {
  .ant-menu-item:hover {
    color: $primary !important;
  }
  .ant-menu-item-selected,
  .ant-menu-item-active {
    color: $primary !important;
    background-color: transparent !important;
  }

  .ant-menu-item:focus,
  .ant-menu-item:active {
    color: $primary !important;
  }
}

// .custom-tab {
//   .ant-tabs-tab {
//     color: #7c7c7c !important;
//     @apply text-base  #{!important};
//   }
//   .ant-tabs-tab-active {
//     a {
//       color: #000000 !important;
//     }
//   }
//   .ant-tabs-nav {
//     margin: 0;
//     min-height: 70px !important;

//     .ant-tabs-nav-list {
//       // gap: 0 15px;
//     }
//   }
// }

.event-input {
  .ant-form-item .ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: auto;
    max-height: 0 !important;
  }
}

td.ant-table-cell.ant-table-cell-ellipsis {
  // padding: 16px 11px !important;
}

.event-input {
  .ant-input {
    text-indent: 0px;
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .ant-input-affix-wrapper {
    .ant-input {
      padding: 4px 2px !important;
    }
  }
  input#description {
    padding: 4px 2px !important;
  }
  :where(.css-dev-only-do-not-override-17iqx48).ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 10px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Popins;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 5px;
    transition: all 0.2s;
  }
}

.event-input {
  input#description:not(.ant-input-affix-wrapper .ant-input) {
    padding: 4px 10px !important;
  }

  .ant-input-affix-wrapper input {
    padding: 4px 2px !important;
  }

  input#event_type {
    padding: 4px 10px !important;
  }
  span.ant-table-column-title {
    margin-left: 11px;
  }
}

.input-height {
  .ant-input {
    border: 1px solid #dddae9;
    color: black;
    min-height: 25px !important;
    // min-height: 39px !important;
    box-shadow: none !important;
    border-radius: 10px;
  }
}

.segmented-custom {
  background-color: white;
  padding: 0;
  border: 1px solid #dddae9;
  border-radius: 10px;
  margin-right: 10px;
  .segmented-label {
    min-height: 40px !important;
    line-height: 40px !important;
    min-width: 100px;
    text-align: center;
    border-right: 1px solid #dddae9;
    cursor: pointer;
  }
  .segmented-label:last-child {
    border-right: none !important;
  }
  .segmented-label-selected:last-child {
    border-right: none !important;
  }
  .segmented-label:hover {
    background-color: #f4f6fa;
  }
  .segmented-label-selected {
    background-color: #f4f6fa;
    min-height: 40px !important;
    line-height: 40px !important;
    min-width: 100px;
    text-align: center;
    border-right: 1px solid #dddae9;
    cursor: pointer;
  }
  .segmented-label:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .segmented-label:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .segmented-label-selected:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .segmented-label-selected:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.event-detail-card {
  .ant-card-body {
    padding: 10px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.attendee-dragger {
  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-upload-list-item {
    height: 50px !important;
  }
  .ant-upload-list-item-thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-upload-list-item-image {
    height: 30px !important;
    width: 30px !important;
  }
}
.product-container {
  .ant-input-number-input-wrap input {
    color: black !important;
  }

  .ant-input-number {
    width: 60px !important;
    text-align: center;
    border: none !important;
  }

  .ant-btn {
    height: 40px;
    border-radius: 5px !important;
    width: 40px;

    &:focus,
    &:active,
    &:hover {
      border-color: rgb(221 218 233) !important;
      color: black !important;
    }
  }
}

.main-upload {
  .ant-upload {
    height: 23px !important;
    width: 27px !important;
    padding: 4px 6px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #6883fd;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 6px;

    .ant-upload-select:hover {
      border: none !important;
    }
  }
}

.listtype {
  .ant-btn-dangerous {
    border: none !important;
    background-color: transparent !important;

    &:focus,
    &:hover,
    &:active {
      border: none !important;
    }
  }
}

.common-modal {
  .ant-modal-content {
    background-color: #fbfbff;
    border-radius: 20px;
  }
}

.delete-modal {
  .ant-modal-content {
    background-color: #fbfbff;
    border-radius: 20px;
  }
}
/* .product-slider {
  .slick-track {
    width: 100% !important;
    .slick-slide {
      flex: 1;
    }
  }
} */
.description-card {
  .ant-card-body {
    padding: 0 !important;

    .ant-input {
      padding-top: 10px !important;
      padding-left: 10px !important;
      border: none !important;
    }
  }
}

.begin-input {
  .ant-select .ant-select-selector {
    min-height: 39px !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none;
    border-color: #dddae9 !important;
  }
}
.selected-input .ant-select-selector {
  min-height: 31px !important;
}

.material-slider {
  .slick-track {
    .slick-slide:nth-child(2) {
      display: none !important;
    }
  }
}

.custom-scroll-barr {
  overflow-y: auto;
  max-height: 500px;
}

.custom-scroll-barr::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.custom-scroll-barr::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scroll-barr::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scroll-barr::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.checkbox-group-horizontal {
  display: flex;
  flex-direction: "column";
  // gap: 10px;
  // align-items: center;
}

.collaps-padding {
  .ant-collapse-content-box {
    padding-block: 0px !important;
  }
  .ant-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 5px 16px !important;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
  }
}

.product-container-form {
  .ant-collapse-content-box {
    padding-block: 0px !important;
  }
  .ant-collapse-header {
    padding: 5px !important;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
  }
}

.custom-scroll-barr-emp {
  overflow-y: auto;
  max-height: 700px;
}

.custom-scroll-barr-emp::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.custom-scroll-barr-emp::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scroll-barr-emp::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scroll-barr-emp::-webkit-scrollbar-thumb:hover {
  background: #555;
}
