.sales-orders-table {
  .ant-select .ant-select-selector {
    max-height: 36px !important;
    background-color: white !important;
    min-height: 36px !important;
    border-radius: 5px !important;
  }

  .ant-input-affix-wrapper {
    max-height: 36px !important;
    min-height: 36px !important;
    border-radius: 5px !important;
  }
}

.typo-black {
  color: black !important;
}

.sales-order-select {
  .ant-select-selector {
    background-color: white !important;
  }
}

.sales-order-datepicker {
  background: white !important;
}

.emp-tracking-container {
  #erp-tracking-timeline {
    .ant-timeline-item-head {
      background-color: transparent !important;
    }

    .ant-timeline-item-label {
      width: 5% !important;
      text-align: left !important;
    }

    .ant-timeline-item-head-custom,
    .ant-timeline-item-tail,
    .ant-timeline-item-head {
      inset-inline-start: 7% !important;
    }

    .ant-timeline-item-content {
      inset-inline-start: 10% !important;
      width: 80% !important;
    }
  }

  .tracking-collapse .ant-collapse-header {
    padding: 10px 15px !important;
  }

  .submit-btn {
    border: 1px solid #e8e6f0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    &:hover {
      background-color: transparent !important;
    }
  }

  .search-input {
    border: 1px solid #e8e6f0 !important;

    &:hover,
    &:focus {
      border: 1px solid #e8e6f0 !important;
    }
  }

  /* Hide scrollbar for WebKit browsers */
  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  /* Hide scrollbar for Firefox */
  .hide-scrollbar {
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Internet Explorer and Edge */
  .hide-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer and Edge */
  }

  #emp-list {
    .ant-list-item-meta {
      align-items: center !important;
      .ant-list-item-meta-title {
        margin-bottom: 0 !important;
      }
    }
  }
}
.custom-tail .ant-timeline-item-tail {
  // position: relative;
  // white-space: nowr;;
  --dynamic-content: "0KM";
  border: 1px dashed #d8d8d8 !important;
}

.custom-tail .ant-timeline-item-tail::before {
  content: var(--dynamic-content);
  position: absolute;
  left: 50%;
  top: 50%;
  white-space: nowrap;
  padding: 4px;
  transform: translate(-50%, -50%);
  z-index: 4;
  font-size: 11px;
  text-transform: uppercase;
  background-color: #fbfbff !important;
  color: #7c7c7c;
  font-weight: bold;
  box-shadow: 0px 0px 3px 0px #0000001a;
}

.blink {
  animation: blink-animation 1.5s ease-in-out infinite;
}

@keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.marker-icon {
  border: none !important;
  background-color: transparent !important;
}

.sales-order-list {
  .ant-list-split .ant-list-item {
    border-bottom: none !important;
  }
}
