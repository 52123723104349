.notifications-popover .ant-popover-inner {
  border-radius: 8px;
}

.notification-popover {
  width: 500px !important;
}

.notification-list {
  max-height: 400px;
  overflow-y: auto;

  .ant-list-item-meta-avatar {
    margin-inline-end: 12px !important;
  }
}

.notification-list::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
.notification-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.notification-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.notification-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.notification-item {
  padding: 12px 16px;
  transition: background-color 0.3s;
  border-bottom: 1px solid #f0f0f0;
}

.blue-dot {
  width: 6px;
  height: 6px;
  background-color: #53acff;
  opacity: 0;
  border-radius: 50%;
  display: inline-block;
}

.notification-item.read {
  opacity: 0.95;

  .title {
    font-weight: 600;
  }
}

.notification-item.unread {
  .title,
  .description {
    font-weight: 600;
  }
  .notification-module {
    font-weight: 500;
  }

  .blue-dot {
    opacity: 1;
  }
}

.notification-center-custom {
  .ant-menu-item {
    border-radius: 0px !important;
  }
  .ant-menu-item-selected{
    background-color: rgba(232, 230, 240, 0.4) !important;
    color: black !important;
  }

  .ant-menu-item {
    height: 40px;
    line-height: 40px;
    padding-inline: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    
    margin-inline: 0px !important;
    margin-block: 0px !important;
    width: calc(100% - 0px) !important;
  }
}
