.ant-btn,
.ant-btn-default {
  @apply border outline-none border-[#dddae9] hover:border-transparent focus:border-transparent
  /* min-h-[35px] */
  rounded-[10px] font-popinsMedium;
}

.ant-input:placeholder-shown {
  color: #676767;
  font-family: Popins;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #d9d9d9 !important;
}

.antd-country-phone-input {
  padding: 0 11px !important;
}

.timezone-select .ant-select-selector {
  border: none !important;
}

.error-border {
  border: 1px solid #ff4d4f !important;

  &:hover {
    border: 1px solid #ff4d4f !important;
  }
}

.phone-error {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.phone-error.visible {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
  /* Delay the transition */
}

.w-image {
  width: 200px !important;
}

.main-bg {
  background: url("../img/main-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.main-section {
  .ant-input-group-addon {
    min-height: 45px !important;
    height: 45px !important;
    padding: 0 !important;
  }

  .icon-btn {
    border: none;
    background: white;
    border-right: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;

    &:hover {
      color: #6883fd;
      background: white !important;
    }

    &:last-child {
      border-top-right-radius: 7px !important;
      border-bottom-right-radius: 7px !important;
      border-right: none !important;
    }
  }

  .ant-pagination {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dddae9;
    padding: 10px 0;
    box-shadow: 0px 0px 20px 0px #b8c9d633;
  }

  .custom-opacity {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.custom-checkbox {
  .ant-checkbox-wrapper {
    width: 100% !important;
    padding: 3px 0;
  }
}

.custom-input-sec {
  .ant-input-affix-wrapper {
    background: white !important;
  }

  .ant-input-group-addon {
    border-start-end-radius: 7px !important;
    border-end-end-radius: 7px !important;
  }

  .default-button {
    background-color: #fff !important;
  }
}

.active-link {
  color: $brand !important;
  font-weight: 600 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* styles.css */
.modal-select .ant-select-selector {
  min-height: 30px !important;
  border-radius: 5px !important;
  min-width: 150px !important;
}

.role-select .ant-select-selector {
  min-height: 32px !important;
  border-radius: 5px !important;
  min-width: 150px !important;
  width: 200px;
}

.gray-hover:hover {
  color: white !important;
}

.master-menu {
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 1368px) {
  .master-menu {
    height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.master-menu::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.master-menu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  transition: background-color 0.3s;
}

.master-menu::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.master-menu::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.06);
}

// css for suffix of input
.suffix-css {
  .ant-input-group-addon {
    background: white !important;
    border-radius: 10px;
    color: #676767;
  }
}

.skeleten-div {
  .skeleton-first-input {
    width: 30% !important;
    margin-top: 10px !important;
  }

  .skeleton-last-input {
    width: 70% !important;
    margin-top: 10px !important;
  }

  .ant-skeleton-input {
    width: 100% !important;
    padding: 2px 0px 2px 0px !important;
  }
}

.skeleten-div-div {
  .skeleton-input {
    width: 30% !important;
    margin-top: 2px !important;
  }

  .skeleton-input-sec {
    width: 70% !important;
    margin-top: 2px !important;
  }

  .ant-skeleton-input {
    width: 100% !important;
  }
}

.antd-cusom-input {
  .ant-skeleton-input {
    width: 370px !important;
    margin-left: 20px !important;
  }
}

.antd-second-custom-input {
  width: 100% !important;

  .ant-skeleton-input {
    width: 93% !important;
    margin-left: 20px !important;
  }
}

.antd-second-feedback-input {
  .ant-skeleton-input {
    width: 900px !important;
    margin-left: 20px !important;
  }
}

.slider-antd-shimmer {
  .slider-antd-shimmer {
    width: 950px !important;
  }
}

.input-antd-custom {
  .ant-skeleton-input {
    width: 400px !important;
  }
}

.business-partner-shimmer {
  .ant-skeleton-input {
    width: 410px !important;
  }
}

.weekstart-shimmer {
  .ant-skeleton-input {
    width: 800px !important;
  }
}

.import-card {
  width: 100%;

  .ant-card-body {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.excel-download-btn {
  border: 1px solid #d4d4d4 !important;
  background-color: white !important;
  border-radius: 6px !important;

  &:hover {
    border: 1px solid #d4d4d4 !important;
    color: black !important;
  }
}

// business partner filter drawer css
.business-filter-drawer {
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    margin-inline-end: 0px;
  }

  .ant-drawer-body {
    padding-right: 5px !important;
  }
}

//drawer scroll css
.drawer-scroll {
  height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 1368px) {
  .drawer-scroll {
    height: calc(100vh - 170px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.drawer-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.drawer-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  transition: background-color 0.3s;
}

.drawer-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.drawer-scroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.06);
}

// pagination selector css
.custom-pagination {
  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background-color: white !important;
    min-height: 35px !important;
  }

  .ant-select-selector {
    .ant-select-selection-search {
      display: none !important;
    }
  }
}

.scroll-container {
  overflow-x: hidden;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color of the scrollbar track */
}

.event-table-container {
  min-height: calc(100vh - 120px) !important;
  height: calc(100vh - 120px) !important;
}

.main-table-container {
  min-height: 100vh;
  overflow-y: hidden;
  max-height: 100%;
  height: 100vh;

  .ant-table-sticky-scroll-bar {
    z-index: 0;
  }

  .ant-table-container .ant-table-body::-webkit-scrollbar,
  .ant-table-container .ant-table-content::-webkit-scrollbar {
    height: 5px;
    /* Height of the horizontal scrollbar */
    width: 5px;
    /* Width of the vertical scrollbar */
    position: fixed !important;
  }

  /* Scrollbar thumb customization (draggable part) */
  .ant-table-container .ant-table-body::-webkit-scrollbar-thumb,
  .ant-table-container .ant-table-content::-webkit-scrollbar-thumb {
    // background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }

  /* Scrollbar track customization (background area) */
  .ant-table-container .ant-table-body::-webkit-scrollbar-track,
  .ant-table-container .ant-table-content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* For Firefox - Adjust scrollbar width (applies to both vertical and horizontal) */
  .ant-table-container .ant-table-body,
  .ant-table-container .ant-table-content {
    scrollbar-width: thin;
    /* Makes the scrollbar thinner */
    scrollbar-color: rgba(0, 0, 0, 0.4) #f1f1f1;
    /* Thumb and track colors */
  }
}

.table-popover {
  .ant-popover-inner {
    padding: 10px 0 !important;
  }
}

.react-resizable-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -4px;
  z-index: 1;
  width: 8px;
  height: 100%;
  display: flex;
  opacity: 0;
  justify-content: center;
  /* cursor: col-resize; */
  cursor: ew-resize;
  background-image: none !important;
}

.react-resizable-handle:hover {
  opacity: 1;
}

.react-resizable-handle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  border-radius: 5px;
  height: 100%;
  background-color: rgba(182, 183, 185, 0.7);
  transition: background-color 0.2s ease;
}

.react-resizable-handle:hover::after {
  background-color: gray;
}

.remove-primary-btn-css {
  border-radius: 5px !important;
  border-width: 1px !important;
  border: none !important;
  font-family: inherit !important;
  outline: none !important;
  outline-offset: 0px !important;

  &:disabled {
    background-color: #6883fd !important;
  }
}

.remove-outline-btn-css {
  border-radius: 5px !important;
  border-width: 1px !important;
  font-family: inherit !important;
  outline: none !important;
  outline-offset: 0px !important;
}

.remove-css {
  border-radius: 5px !important;
  text-indent: 0 !important;
  min-height: 32px !important;

  .ant-select-selector {
    border-radius: 5px !important;
    min-height: 32px !important;
  }
}

.remove-checkbox-css {
  .ant-checkbox-checked .ant-checkbox-inner {
    border: none !important;
    background-color: #6883fd !important;
  }
}

.filter-drawer {
  .ant-drawer-body {
    padding: 0px !important;
  }
}

.filter-drawer-scroll {
  height: calc(100vh - 116px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 1368px) {
  .filter-drawer-scroll {
    height: calc(100vh - 170px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.common-table .ant-table-container {
  border-inline-start: none !important;
}

.drag-preview {
  padding: 8px;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.5;
}
.hpin-modal {
  .ant-modal-footer {
    margin-top: 0px !important;
  }

  .ant-otp-input {
    padding-right: 10px !important;
  }
}

.editable-row td:first-child {
  background-color: white;
  z-index: 999;
}

.editable-row td:last-child {
  background-color: white;
  z-index: 999;
}

// multi select css
.ant-select-multiple .ant-select-selection-wrap {
  align-self: auto;
}

// business partner filter form input css
.filter-form {
  .ant-input {
    text-indent: 0;
  }
}
