.expense-voucher-form {
  .ant-picker {
    background: white !important;
  }

  .borderless {
    border: none !important;
    background: transparent !important;

    .ant-select {
      .ant-select-selector {
        border: none !important;
      }
    }

    .ant-input-number {
      border: none !important;
    }
  }
}

.plus-btn {
  border: 1px solid #d9d9d9;
  box-shadow: none !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #1677ff;
    box-shadow: none !important;
  }
}

.gray-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.gray-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  transition: background-color 0.3s;
}

.gray-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.gray-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.06);
}

.expense-items-table {
  .ant-table-pagination {
    background-color: transparent !important;
    border-radius: 10px;
    border: none !important;
    padding: 10px 0 !important;
    box-shadow: none !important;
  }
}
