.beat-planning-steps {
  .ant-steps-item-content {
    margin-top: 0 !important;
  }
  .ant-btn-primary:disabled {
    background-color: #ababab !important;
  }
}

/* .nex-btn {
  &:disabled {
    background-color: #6883fd !important;
  }
} */

.small-table {
  .ant-table-thead {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #6883fd !important;
      }
    }
    .ant-checkbox-indeterminate {
      .ant-checkbox-inner:after {
        background-color: #6883fd !important;
      }
    }
  }
  .ant-table-row-selected {
    .ant-checkbox-inner {
      background-color: #6883fd !important;
    }
  }
}

.occurrences-input-wrapper {
  .ant-input-number .ant-input-number-input {
    color: black !important;
  }
  .ant-input-number-suffix{
    position: absolute;
  }
}

.repeat-unit {
  .ant-input-number-handler-wrap {
    border-radius: 10px !important;
  }
}

.repeat-unit-select {
  .ant-select-selector {
    min-width: 100px !important;
  }
}

.add-task-modal {
  .ant-modal-content {
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.ant-table-filter-dropdown {
  .ant-dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }

  .ant-dropdown-menu::-webkit-scrollbar {
    margin-right: 8px;
    border-radius: 20px;
    width: 8px;
  }

  .ant-dropdown-menu::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 20px;

    height: 50px;
  }

  .ant-dropdown-menu::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  .ant-dropdown-menu::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }

  .ant-table-filter-dropdown-btns {
    .ant-btn-link {
      border-radius: 4px !important;
      border-color: rgba(221 218 233) !important;
      color: gray !important;
    }
    .ant-btn-primary {
      border-radius: 4px !important;
      border: 1px solid transparent !important;
    }
  }
}

.sales-orders-filter-container {
  .sales-order-filters {
    .ant-collapse-content-box {
      padding-block: 0px !important;
    }
    .ant-collapse-header {
      padding: 5px !important;
      color: rgba(0, 0, 0, 0.88);
      line-height: 1.5714285714285714;
      cursor: pointer;
      transition: all 0.3s, visibility 0s;
    }
  }
  .material-search-input {
    min-height: 40px !important;
    max-height: 40px !important;
    border-radius: 5px !important;
    margin: 10px 0;
    text-indent: 0 !important;
  }
}

.style-custom {
  display: flex;
  justify-content: space-between;
}
