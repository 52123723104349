.main-card {
  .ant-input {
    text-indent: 2px !important;
  }
  textarea {
    padding: 10px 11px !important;
  }
  .ant-card-body {
    padding: 24px !important;
  }
  .gray-button:hover {
    color: white !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-tree-node-content-wrapper:hover {
  background-color: transparent !important;
}

.ant-tree-node-selected {
  background-color: transparent !important;
}

.ant-tree-list-holder-inner {
  .ant-tree-title {
    font-weight: 600 !important;
  }
}

.main-wrapper {
  #custom-drop {
    color: #7c7c7c !important;
    .ant-btn {
      height: 35px !important;
    }

    .ant-btn-default {
      background-color: #f4f6fa !important;
      border-radius: 5px !important;

      &:active,
      &:focus {
        border: 1px solid #dddae9 !important;
        color: black !important;
      }
    }

    &:hover,
    &:focus {
      .ant-btn-default {
        border-color: #dddae9 !important;
      }
    }
    .ant-dropdown-trigger {
      background-color: #f4f6fa !important;
      color: black;

      &:hover {
        border-color: #dddae9 !important;
      }
      &:focus {
        border-color: #dddae9 !important;
      }
    }
    .ant-btn-compact-first-item {
      border-start-end-radius: 0 !important;
      border-end-end-radius: 0 !important;
      &:hover {
        color: black !important;
      }
      &:disabled {
        color: gray !important;
      }
    }
    .ant-btn-compact-last-item {
      border-radius: 5px !important;
      border-start-start-radius: 0 !important;
      border-end-start-radius: 0 !important;
      &:hover {
        color: black !important;
      }
    }
  }
  .hierarchy-card {
    .ant-card-body {
      align-items: start;
      width: 100%;
      display: flex;

      .tree-node-actions {
        display: none;
      }

      .tree-node-actions .action-icon {
        margin-left: 8px;
        cursor: pointer;
      }

      .ant-tree-treenode:hover .tree-node-actions {
        display: flex !important;
        align-items: center;
      }
    }
  }
}

.custom-checkbox {
  .ant-checkbox-wrapper {
    width: 100%;

    @apply lg:text-base text-base;
  }
}

.card-size {
  .ant-card-body {
    padding: 0px !important;
  }
  .ant-card .ant-card-body {
    padding: 0px;
    border-radius: 0 0 6px 6px;
  }
}

.custom-fields-container {
  .ant-input {
    text-indent: 0 !important ;
  }
  .options-card {
    max-height: 300px;

    .ant-card-head {
      min-height: 40px !important;

      .ant-card-head-title {
        font-weight: 500 !important;
        font-size: 16px !important;
      }
    }
    .ant-card-body {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 300px;
      padding: 12px !important;
    }

    .ant-card-body::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .ant-card-body::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    .ant-card-body::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    .ant-card-body::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.custom-field-card {
  .ant-card-body {
    padding: 5px 1.25rem !important;

    .search-container {
      .ant-input-affix-wrapper {
        min-height: 35px !important;
        margin-top: 10px;
      }
    }

    .custom-field-table-container {
      .ant-table-placeholder {
        z-index: 0 !important;
      }

      .react-international-phone-input-container {
        margin-bottom: 0 !important;
        .react-international-phone-country-selector-button,
        .react-international-phone-input {
          height: 35px !important;
        }
      }
      .ant-input {
        font-size: 13px !important;
        min-height: 35px !important;
        text-indent: 0 !important;
      }

      .ant-picker {
        min-height: 35px !important;
      }

      .ant-input-number {
        font-size: 13px !important;
        min-height: 0 !important;
      }
      .ant-input-affix-wrapper {
        min-height: 35px !important;

        .ant-input {
          font-size: 13px !important;
          min-height: 0 !important;
        }
      }
      .ant-select {
        font-size: 13px !important;
        .ant-select-selector {
          font-size: 13px !important;
          min-height: 35px !important;
          background-color: white !important;

          &:hover {
            background-color: white !important;
          }
        }
      }
    }
  }
}

.dotted-line {
  stroke: gray !important;
  stroke-width: 1;
  stroke-dasharray: 5, 5;
}

.organogram-container {
  .orgonagram-card {
    overflow-y: auto;
    height: 100%; /* Adjust as needed */

    .ant-card-body {
      padding: 0 !important;
    }
  }

  .organogram-header {
    .ant-input {
      min-height: 40px !important;
      border-radius: 5px !important;
    }
    .plus-button {
      border-radius: 6px !important;
      min-height: 40px !important;
    }
  }

  .highlighted-node {
    background-color: #ffff00;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  }

  .highlighted-node:hover {
    background-color: #ffff00;
  }
}

.bulk-update-buttons {
  .root-btn {
    &:disabled {
      background-color: #6883fd !important;
      opacity: 0.8;
    }
  }
}
