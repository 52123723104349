.register-container {
  .site-form-item-icon {
    font-weight: bold;
    margin-left: 4px;
  }
  .ant-input {
    text-indent: 7px;
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    color: #3e3e3e;
  }

  .google-btn {
    color: black !important;
    box-shadow: 0px 0px 6px 0px #0000001a !important;

    &:hover {
      background-color: rgba(255, 255, 255, 0.85) !important;
      color: black !important;
    }
  }

  .ant-otp {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 0 10px !important;

    .ant-otp-input {
      text-indent: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 70px !important;
      margin-right: auto;
      // align-items: center;
      height: 70px;
    }
    // width: 70px;
  }
}

:root {
  /*   --toast-close-button-start: unset !important;
  --toast-close-button-end: 0 !important; */
  --react-international-phone-height: 45px;
  --react-international-phone-border-radius: 10px;
  --react-international-phone-font-size: 14px;
  --react-international-phone-border-color: #dddae9;
  --react-international-phone-text-color: black;
}

button:focus {
  outline: none !important;
}

.site-form-item-icon {
  font-weight: bold;
  margin-left: 4px;
}
.ant-input {
  text-indent: 7px;
  color: rgba(0, 0, 0, 0.8) !important;
}

.box-bg {
  background: rgba(69, 103, 255, 0.04);
}

.react-international-phone-input {
  width: 100%;
}

.custom-err-border {
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      border-color: #ff4d4f;
    }
  }
  .react-international-phone-input {
    border-color: #ff4d4f;
  }
}

.react-international-phone-country-selector-button {
  padding: 8px !important;
}
.otp-input-con {
  .ant-form-item .ant-row .ant-form-item-control > :nth-child(2) {
    display: none !important;
  }

  #normal_login_mobile_otp_help {
    display: none !important;
    opacity: 0 !important;
  }
  #normal_login_email_otp_help {
    display: none !important;
    opacity: 0 !important;
  }
}

.radio-wrapper {
  .ant-radio-wrapper {
    &:hover > .ant-radio-inner {
      border-color: $secondary;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: $secondary !important;
      background-color: $secondary !important;
    }
  }
}

.continue-typo .ant-typography-disabled {
  color: #adb5bd !important;
  &hover {
    color: #adb5bd !important;
  }
}

.company-dropdown {
  .ant-select-selector {
    color: $primary !important;
  }
}

.begin-model {
  .ant-modal-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/bg-model.svg);
  }
}

.email-input {
  padding-left: 4px !important;
}

.begin-input {
  background-color: white !important;

  &:focus,
  &:active {
    background: white !important;
  }
}
