@media (max-width: 1919px) {
  .active-tabs {
    font-size: 17px;
    padding: 0px 12px;
  }
}

@media (max-width: 1800px) {
  .active-tabs {
    font-size: 18px !important;
    padding: 0px 12px;
  }
}

@media (max-width: 1700px) {
  .active-tabs {
    font-size: 15px !important;
    padding: 0px 10px;
    white-space: nowrap;
  }
}

.active-tabs {
  position: relative;
  padding-bottom: 5px;
  transition: color 0.3s ease;
}

.active-tabs::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  background-color: #6883fd;
  //   transition: width 0.3s ease;
  top: 45px;
}

.active-tabs.active {
  color: black !important;
}

.active-tabs.active::before {
  width: 55%;
  margin: auto;
}

.edit-input {
  .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 18px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Popins;
    position: relative;
    display: inline-block;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
  }
}

.edit-input {
  .ant-input {
    border: 1px solid #dddae9 !important;
    color: black;
    box-shadow: none !important;
    border-radius: 4px;
  }
}


.company-input {
  .ant-input {
    min-height: 23px !important;
    border-radius: 5px !important;
  }
  .ant-input-affix-wrapper {
    min-height: 23px !important;
    border-radius: 5px !important;
  }
  .ant-select-selector {
    min-height: 23px !important;
    border-radius: 5px !important;
  }
  .ant-picker {
    min-height: 23px !important;
    border-radius: 5px !important;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.company-business-hours {
  background-color: #fff;
}
