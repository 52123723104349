.inner-table thead > tr > th {
  border-top: 1px solid #f0f0f0 !important;
}
/* 
.inner-table .ant-table.ant-table-small tr > th,
td {
  padding: 5px 5px !important;
} */

.filters {
  .ant-select .ant-select-selector {
    background-color: white !important;
  }
  .ant-picker {
    background: white !important;
  }
}
