@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: 65vh !important;
}
.fc .fc-button {
  background: #ffffff;
  border: 1px solid #dddae9;
  color: black;
}
.fc .fc-button:hover {
  background: #ffffff;
  border: 1px solid #6883fd;
  color: #6883fd;
}
.fc .fc-button:focus {
  background: #ffffff !important;
  border: 1px solid #6883fd !important;
  outline: none !important;
  box-shadow: none !important;
  color: #6883fd !important;
}
.fc .fc-button:disabled {
  background: #ffffff !important;
  border: 1px solid #d4d4d4 !important;
  outline: none !important;
  box-shadow: none !important;
  color: #d4d4d4 !important;
  cursor: not-allowed;
}

.full-calendar .fc {
  border-radius: 15px;
  background-color: white;
}

.full-calendar .fc-timegrid-col {
  background-color: white;
}

.full-calendar .fc-timegrid-slot {
  background-color: white;
}

.full-calendar .fc-timegrid-bg .fc-timegrid-slot {
  background-color: white;
}
.fc-timegrid-now-indicator-container {
  background-color: white;
}
.fc-event {
  border-radius: 10px;
}
.fc thead {
  background-color: white;
  border-radius: 10px !important;
}
.fc .fc-col-header-cell-cushion {
  padding: 10px !important;
}
.fc .fc-col-header-cell-cushion:hover {
  color: black !important;
}

// .slick-track{
//   :nth-child(3n) {
//     // border-right: 1px solid red !important;
//     border-radius: 10px !important;

// }
// }
.custom-disable.ant-picker {
  min-height: 45px;
  border-color: #dddae9 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 10px;

  background-color: transparent !important;

  &.ant-picker-disabled {
    background-color: #f5f5f5 !important;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    border-color: #dddae9 !important;
    box-shadow: none !important;
  }

  &.ant-picker-status-error {
    border-color: #ff4d4f !important;
  }

  .ant-picker-input {
    input {
      color: black !important;
    }
  }
}

.custom-inputnumber {
  // Default disabled background
  background-color: #f5f5f5 !important;
}

.custom-inputnumber.enabled-occurrence {
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-125b9ri).ant-input-number-affix-wrapper {
  padding-inline-start: 0 !important;
}

.small-table.company-users-table .ant-table-cell {
  padding: 6px 10px !important;
}

.small-table.beat-master-table .ant-table-cell {
  padding: 6px 10px !important;
}
