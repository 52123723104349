.profile-section {
  background: linear-gradient(
    0deg,
    rgba(248, 251, 255, 0) 0%,
    rgba(223, 230, 255, 0.2) 40%,
    /* Adjust the opacity value here */ rgba(218, 228, 255, 0.2) 51%,
    rgba(206, 226, 255, 0.2) 61%,
    rgba(186, 205, 255, 0.2) 72%,
    rgba(158, 191, 255, 0.2) 83%,
    rgba(122, 167, 255, 0.2) 93%,
    rgba(69, 103, 255, 0.2) 100%
  );
}

.active-border {
  cursor: pointer;
  position: relative;
  transition: border-bottom 0.8s ease; /* Example transition property */
}

.active-border::after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #4567ff;
  box-shadow: 0px -3px 8px 0px #4567ffb2;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  // left: 0;
  transition: border-bottom 0.8s ease;
  bottom: -1px;
}

.week-start-container {
  .ant-radio-group {
    width: 100%;
    display: flex;
    @apply lg:flex-nowrap flex-wrap;

    .ant-radio-wrapper {
      gap: 0 10px;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-checkbox-wrapper {
    flex: 1;
    align-items: center;
    gap: 0 10px;
    justify-content: center;

    .ant-checkbox {
      @apply md:text-[15px] text-xs font-popinsRegular;
    }

    &:last-child {
      border-right: none !important;
    }
  }

  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background: $primary !important;
    }
  }

  .ant-checkbox-wrapper:not(:last-child) {
    border-right: 0.9px solid #dddae9 !important ;

    .ant-checkbox-disabled .ant-checkbox-inner:after {
      border-color: white !important;
    }
  }

  .last-item {
    .ant-checkbox-wrapper {
      border-right: none !important;
    }
  }
}

.timepicker-parent {
  .ant-picker {
    min-height: 0 !important;
  }
}

#contact-otp-form {
  .contact-input {
    min-height: 40px !important;
    border-radius: 10px !important;
    text-indent: 0px !important;
  }
  .ant-otp {
    width: 100% !important;
    .verify-otp-input {
      text-indent: 0px !important;
      height: 70px !important;
    }
    .ant-otp-input {
      text-indent: 0 !important;
      height: 60px !important;
    }
  }
  .ant-btn {
    border: none !important;
    height: 40px !important;
    box-shadow: none !important;
  }
}
