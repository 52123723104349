.custom-color {
  .ant-picker {
    background-color: white !important;
    width: 97%;
  }

  .ant-btn {
    font-size: 14px;
    line-height: 1.5714285714;
    height: 44px;
    padding: 4px 19px;
    border-radius: 9px;
  }
}

.social-links-input {
  .ant-input {
    border: 1px solid #dddae9 !important;
    color: black;
    min-height: 33px !important;
    box-shadow: none !important;
    border-radius: 5px;
  }

  .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 3px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Popins;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 5px;
    transition: all 0.2s;
  }

  .ant-typography-edit-content textarea {
    margin-top: 15px !important;
    -moz-transition: none;
    height: 1em;
  }

  .ant-input {
    text-indent: 0px;
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .ant-btn {
    /* font-size: 14px; */
    /* line-height: 1.5714285714; */
    /* height: 44px; */
    /* padding: 4px 19px; */
    /* border-radius: 9px; */
  }
}

.input-box {
  margin-bottom: 10px;
  border-radius: 5px;
  border-color: #dddae9;

  .ant-btn {
    height: 0px !important;
    border: none !important;
  }

  .ant-divider-vertical {
    /* position: relative; */
    /* top: -0.06em; */
    /* display: inline-block; */
    height: 2.9em;
    // /* margin-inline: 8px;
    /* margin-block: 0; */
    vertical-align: middle;
    /* border-top: 0; */
    border-inline-start: 1px solid rgba(5, 5, 5, 0.06);
  }
}

.custom-devider {
  .ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    height: 0.9em;
    /* margin-inline: 8px; */
    margin-block: 0;
    vertical-align: middle;
    border-top: 0;
    border-inline-start: 1px solid rgba(5, 5, 5, 0.06);
  }
}


