@import "variables.scss";

.ant-input-affix-wrapper {
  border: 1px solid $border-color !important;
  min-height: $min-height; // Adjust the value as needed
  box-shadow: none !important;

  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }

  &.ant-input-status-error {
    border-color: #ff4d4f !important;
  }

  .ant-input {
    min-height: 0 !important;
    border-radius: $radius;
    background-color: $transparent;
    outline: none;
    color: black !important;
    box-shadow: none !important;
  }
}

.ant-form-item-explain-error {
  padding: 5px 0;
  font-size: 14px !important;
}

.ant-input-prefix {
  color: $primary !important;
}

.ant-input-password {
  min-height: $min-height; // Adjust the value as needed
  background-color: $transparent;
  border: 1px solid $border-color !important;
  border-radius: $radius;

  &.ant-input-status-error {
    border-color: #ff4d4f !important;
  }

  .ant-input {
    border-radius: $radius;
    border: none !important;
    background-color: $transparent;
    outline: none;
    box-shadow: none !important;
  }

  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;

    &.ant-input-status-error {
      border-color: #ff4d4f !important;
    }
  }
}

.ant-input-number {
  border: 1px solid $border-color !important;
  outline: none;
  border-radius: $radius;
  background-color: $transparent !important;
  box-shadow: none !important;

  .ant-input-number-input-wrap {
    background-color: transparent !important;
  }

  &:focus,
  &:hover,
  &:focus-visible {
    background-color: $transparent !important;
    border-color: $border-color !important;
    box-shadow: none !important;
  }

  .ant-input-number-input {
    min-height: $min-height;
  }
}

.ant-input-status-error {
  .site-form-item-icon {
    color: #ff4d4f !important;
  }
}

.ant-input-number-status-error {
  border-color: #ff4d4f !important;

  &:hover {
    border-color: #ff4d4f !important;
  }
}

.ant-picker {
  min-height: $min-height; // Adjust the value as needed
  border-color: $border-color !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: $transparent !important;
  border-radius: $radius;

  &:hover,
  &:focus,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }

  &.ant-picker-status-error {
    border-color: #ff4d4f !important;
  }

  .ant-picker-input {
    input {
      color: black !important;
    }
  }
}

.ant-select {
  height: auto !important;
  border: none !important;
  outline: none !important;
  color: black !important;

  background-color: $transparent;
  box-shadow: none !important;

  .ant-select-selector {
    min-height: $min-height; // Adjust the value as needed
    border-radius: 10px !important;
    background-color: $transparent !important;
    box-shadow: none !important;
    outline: none;

    border-color: $border-color !important;
    // color: $primary;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    .ant-select-selector {
      border-color: $border-color !important;
    }
  }

  &.ant-select-status-error .ant-select-selector {
    border-color: #ff4d4f !important;
  }
}

.ant-checkbox {
  &.ant-checkbox-status-error .ant-checkbox-inner {
    border-color: #ff4d4f !important;
  }
}

.ant-btn {
  outline: none !important;
}

.ant-input {
  border: 1px solid $border-color !important;
  color: black;
  min-height: $min-height !important;
  box-shadow: none !important;
  border-radius: $radius;

  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }

  &.ant-input-status-error {
    border-color: #ff4d4f !important;
  }
}

.ant-input-affix-wrapper {
  border-radius: $radius;

  .ant-input {
    border: none !important;
  }
}

.ant-form-item {
  .ant-form-item-label {
    padding-bottom: 10px !important;
  }
}

.ant-btn-primary:disabled,
.ant-btn-disabled {
  background-color: rgba(21, 39, 120, 0.8) !important;
  color: white !important;
}

.filled-btn {
  background: $secondary !important;
  color: white;
  border: none !important;
}

.outlined-btn {
  background: transparent !important;
  border: 1px solid $border-color !important;
  color: black !important;

  &:hover,
  :focus {
    background-color: transparent !important;
    border: 1px solid $border-color !important;
    color: black !important;
  }
}

.ant-divider-vertical {
  border-inline-start: 1px solid $border-color;
}

.ant-spin .ant-spin-dot-item {
  background-color: $secondary !important;
}

.custom-bg-title {
  .ant-card-head {
    border-radius: 20px 20px 0px 0px;
  }
}

.main-section {
  #custom-drop {
    color: #7c7c7c !important;

    .ant-btn {
      height: 35px !important;
    }

    .ant-btn-default {
      background-color: #f4f6fa !important;
      border-radius: 5px !important;

      &:active,
      &:focus {
        border: 1px solid #dddae9 !important;
        color: black !important;
      }
    }

    &:hover,
    &:focus {
      .ant-btn-default {
        border-color: #dddae9 !important;
      }
    }

    .ant-dropdown-trigger {
      background-color: #f4f6fa !important;
      color: black;

      &:hover {
        border-color: #dddae9 !important;
      }

      &:focus {
        border-color: #dddae9 !important;
      }
    }

    .ant-btn-compact-first-item {
      border-start-end-radius: 0 !important;
      border-end-end-radius: 0 !important;

      &:hover {
        color: black !important;
      }
    }

    .ant-btn-compact-last-item {
      border-radius: 5px !important;
      border-start-start-radius: 0 !important;
      border-end-start-radius: 0 !important;

      &:hover {
        color: black !important;
      }
    }
  }
}

.permission-card {
  border: 1px solid #dddae9;
  border-radius: 20px;

  .ant-card-body {
    padding: 0;
  }
}

.inner-permission-layout {
  background-color: white;
  border-radius: 20px 20px 0px 0px;
}

//custom checkbox
.custom-checkbox {
  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: #6883fd !important;
    }
  }
}

//css for radio button
.ant-radio-wrapper .ant-radio-inner {
  height: 20px;
  width: 20px;
}

.profile-card {
  .ant-input {
    min-height: 25px !important;
    border-radius: 5px !important;
  }

  .ant-input-affix-wrapper {
    min-height: 25px !important;
    border-radius: 5px !important;
  }

  .ant-select-selector {
    min-height: 25px !important;
    border-radius: 5px !important;
  }

  .ant-picker {
    min-height: 25px !important;
    border-radius: 5px !important;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

/* :where(html[dir="ltr"]),
:where([data-sonner-toaster][dir="ltr"]) {
  --toast-close-button-start: unset;
  --toast-close-button-end: unset;
}

li[data-sonner-toast] {
  button {
    position: absolute;
    top: 50%;

    right: 0 !important;
    transform: translate(-50%, -50%);

    svg {
      width: 16px;
      height: 16px;
    }
  }
} */

.cancel-button-style {
  svg {
    width: 16px;
    height: 16px;
  }
}

.delete-modal {
  .ant-modal-confirm-btns {
    &:first-child {
      border-color: rgb(221 218 233) !important;

      &:hover {
        border-color: rgb(221 218 233) !important;
      }
    }
  }

  .ant-btn-dangerous {
    border-color: #ff4d4f !important;
  }
}

.my-toast-success {
  border: 0.1px solid rgba(22, 163, 74, 0.3) !important;
}

.my-toast-error {
  border: 0.1px solid rgba(220, 38, 38, 0.3) !important;
}

.my-toast-info {
  border: 0.1px solid rgba(37, 99, 235, 0.3) !important;
}

.my-toast-warning {
  border: 0.1px solid rgba(202, 138, 4, 0.3) !important;
}

.ant-card-bordered {
  border: 1px solid #dddae9 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.drag-scroll {
  user-select: none;
  /* Prevent text selection */
  scroll-behavior: smooth;
  /* Smooth scrolling */
}

// border of popover
.ant-popover .ant-popover-inner {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.ant-popover .ant-popover-arrow::after {
  border: 1px solid #d9d9d9;
  z-index: 999;
}

.ant-popover-placement-bottomRight > .ant-popover-arrow {
  top: 1px;
  right: 40px;
}

//upload button css
.upload-button {
  border: 1px solid #4567ff !important;
  border-radius: 5px !important;
}

.custom-upload {
  .ant-upload-icon {
    display: none;
  }

  .ant-upload-list-item:hover {
    background: none !important;
  }
}

.show-remove-icon {
  .ant-upload-list
    .ant-upload-list-item
    .ant-upload-list-item-actions
    .ant-upload-list-item-action {
    opacity: 1;
  }
}

.ant-form-item .ant-form-item-label > label::after {
  content: none;
}

.dragger-custom .ant-upload-list-item {
  border: 1px solid #dddae9;
  border-radius: 5px;
  padding: 15px 10px;
}

.avatar-uploader.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  border: none !important;
}

.avatar-uploader
  .ant-upload-picture-card-wrapper
  .ant-upload
  .ant-upload-select {
  border: none !important;
}

.avatar-uploader.ant-upload-picture-card-wrapper .ant-upload-select:hover {
  border: none !important;
}

.opportunity-select {
  display: none !important;
}

.remove-arrow {
  .ant-select-arrow {
    display: none;
  }
}

.company-detail-collapse {
  .ant-collapse-header-text {
    flex: none !important;
    margin-inline-end: 0 !important;
  }
}

.bg-color td.input-height {
  background-color: white !important;
  z-index: 2 !important;
}

.beat-master-table td:last-child {
  background-color: white !important;
}

// Collapse css
.custom-collapse {
  .ant-collapse-header {
    border-bottom: 1px solid #dddae9;
    border-radius: 0px !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.currency-menu {
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.currency-menu::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.currency-menu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  transition: background-color 0.3s;
}

.currency-menu::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.currency-menu::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.06);
}

.sync-disabled:disabled {
  background-color: rgba(104, 131, 253, 0.7) !important;
  color: white !important;
}

.custom-panel {
  .ant-collapse-item .ant-collapse-header {
    border-bottom: none !important;
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      border-bottom: 1px solid #eaeaea !important;
    }
  }
}

/* .ant-table .ant-table-header {
  .ant-table-cell {
    padding: 12px 10px !important;
  }
}

*/

.ant-table {
  .ant-table-cell {
    // padding: 5px 10px !important;
    height: 35px !important;
    .ant-badge{
      margin: 10px 0;
    }
  }
}

// pagination selector css
.ant-pagination .ant-pagination-options {
  margin-inline-start: 0;
}

.ant-table-cell {
  height: 35px !important;
  padding: 0 10px !important;
}

.custom-ellipsis-wrapper {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex !important;
  align-items: center;
  width: 100%;

  .custom-image-ellipsis {
    vertical-align: middle;
    flex-shrink: 0;
  }
}

.custom-typo-ellipsis {
  flex-shrink: 1;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  margin: 0 !important;

  .ant-typography-copy {
    display: flex;
  }
}

.layers-control-container {
  visibility: visible !important;
  opacity: 1 !important;
}

.tag-ellipsis {
  display: inline-block;
  max-width: 100%;
  overflow: hidden; /* Hide overflowing text */
  white-space: nowrap; /* Prevent wrapping */
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
  vertical-align: middle; /* Align text properly if inside inline elements */
}
