#login-container {
  .login-form-button {
    border: 1px solid $border-color;
    height: 100%;
    min-width: 100px;
    padding: 6px 10px !important;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }
}
// .layout-main {
//   .ant-layout-header {
//     @apply 2xl:h-[60px]  xl:h-[50px] h-[50px] #{!important};
//   }
// }

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.main-layout-sider {
  font-family: "Poppins", sans-serif !important;

  .ant-layout-sider {
    transition: all 0.3s ease 0s;
  }

  .ant-menu-item-selected {
    // font-weight: 600;
    color: rgba(0, 0, 0, 0.8) !important;

    .ant-menu-item-icon {
      min-width: 17px !important;
      font-weight: 500 !important;
    }
  }
}

.side-menu {
  .ant-menu-item-icon {
    font-weight: 500;
    min-width: 18px !important;
  }
  .sidebar-active {
    color: black !important;
    font-weight: 500 !important;
  }
  .ant-menu-sub {
    .ant-menu-item {
      margin-block: 0;
      padding-left: 26px !important;
    }
  }

  .ant-menu-submenu {
    margin-block: 0px;
    .ant-menu-submenu-title {
      margin-inline: 0 !important;
    }
  }

  .ant-menu-submenu-selected {
    position: relative;
    // color: rgba(0, 0, 0, 0.6) !important;

    margin-inline: 0 !important;
    // background: linear-gradient(
    //   to right,
    //   rgba(138, 159, 245, 0.5),
    //   #fff
    // ) !important;
    background: linear-gradient(
      to right,
      rgb(204 214 255 / 50%),
      #fff
    ) !important;
  }
  .ant-menu-submenu-title:hover{
    background-color: transparent !important;
  }

  .ant-menu-submenu-selected {
    border-radius: 0 !important;
    .ant-menu-submenu-title {
      border-radius: 0 !important;
      // color: rgba(0, 0, 0, 0.6) !important;
      color: black !important;
      margin-inline: 0;

      &::before {
        content: "";
        left: 0;
        position: absolute;
        width: 5px;
        height: 100%;
        border-radius: 0px 5px 5px 0px;
        background-color: rgba(69, 103, 255, 1);
      }
    }
    .ant-menu-submenu-selected .ant-menu-sub .ant-menu-item-selected {
      background: white;
    }
  }

  .ant-menu-submenu .ant-menu-sub .ant-menu-submenu {
    .ant-menu-sub {
      .ant-menu-item {
        padding-left: 72px !important;
      }
    }

    .ant-menu-submenu-title::before {
      position: relative !important;
      content: "";
      color: red;
      height: 0;
      width: 0;
    }
  }

  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: white;
  }

  .ant-menu-item-selected {
    position: relative;
    background: linear-gradient(
      to right,
      rgb(204 214 255 / 50%),
      #fff
    ) !important;
    border-radius: 0;

    &::before {
      content: "";
      left: 0;
      position: absolute;
      width: 5px;
      height: 100%;
      border-radius: 0px 5px 5px 0px;
      background-color: rgba(69, 103, 255, 1);
    }
  }

  .ant-menu-item {
    display: flex;
    margin-inline: 0 !important;
    width: 100% !important;

    &:hover {
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}

.menu-search {
  .ant-input-affix-wrapper {
    border: 1px solid #dddae9 !important;
    min-height: 37px !important;
    box-shadow: none !important;
  }
}

.color-scrollbar::-webkit-scrollbar {
  width: 7px !important;
  height: 5px !important;
}

.color-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 15px !important;
}

/* Handle */
.color-scrollbar::-webkit-scrollbar-thumb {
  background: #b6b6b6 !important;
  border-radius: 15px !important;
}

/* Handle on hover */
.color-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a7a5a5 !important;
}
// master data menu css
.remove-bg {
  .ant-menu-inline {
    background: transparent !important;
  }
}


.root-add-btn{
  .ant-btn-icon{
    display: flex !important  ;
  }
}