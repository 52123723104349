.edit-sub-task {
  textarea {
    border: 1px solid gray !important;
    border-radius: 4px !important;
    min-height: auto !important;
    text-indent: 3px !important;
    height: auto !important;
  }
}

.add-task-input {
  input {
    min-height: 25px !important;
    border-radius: 5px !important;
    text-indent: 3px !important;
    padding: 5px 10px !important;
  }
}

.task-upload {
  .ant-upload {
    height: 40px !important;
    border: none !important;
  }

  /* .ant-upload-list-item-name {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  } */

  .ant-upload-list {
    max-height: 200px;
    overflow: auto;
  }

  .ant-upload-select {
    background-color: transparent !important;
    width: 100% !important;
  }
}

.file-show {
  .ant-image {
    height: 40px !important;
    width: 40px !important;

    img {
      object-fit: cover;
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.status-dropdown .ant-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.status-dropdown .ant-dropdown-menu::-webkit-scrollbar {
  margin-right: 8px; 
  border-radius: 20px;
  width: 8px; /* or your preferred width */
}

.status-dropdown .ant-dropdown-menu::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 20px;

  height: 50px; /* Adjust the thumb height */
}

.status-dropdown .ant-dropdown-menu::-webkit-scrollbar-track {
  background: #f0f0f0;
}

/* Remove scrollbar arrows */
.status-dropdown .ant-dropdown-menu::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

.create-task-modal {
  .ant-modal-content {
    padding: 0 !important;
  }
}
/* 

.custom-design {
  .ant-select-selection-item {
    display: flex;
    align-self: center;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    margin-block: 2px;
    border-radius: 4px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    margin-inline-end: 4px;
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
  }
  .ant-select-selection-item {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
    border-right: 1px solid !important;
    border-left: none !important;
    border-radius: 10px solid !important;
    border-top-left-radius: 17px !important;
    border-bottom-left-radius: 14px !important;
  }
  
  .ant-avatar-sm {
    width: 33px !important;
    height: 36px !important;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black !important;
  }

  .ant-select-selection-item {
    height: 33px !important;
    line-height: 22px;
  }
}

.custom-avatar .ant-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 */