@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .radius {
    @apply rounded-[10px] !important;
  }
  .flex-center {
    @apply flex items-center justify-center !important;
  }
  .filled-button {
    @apply bg-primary text-white hover:text-white md:text-sm text-xs  hover:bg-primary/90 border-none radius font-popinsMedium tracking-wider !important;
  }
  .root-btn {
    @apply bg-brand  text-white hover:text-white md:text-sm text-xs  hover:bg-brand/90 border-none radius font-popinsMedium tracking-wider !important;
  }
  .location-submit-btn {
    @apply bg-brand  text-white hover:text-white md:text-sm text-xs  hover:bg-brand/90 border-none rounded-s font-popinsMedium tracking-wider !important;
  }
  .filled-s-button {
    @apply bg-secondary  hover:bg-secondary/90 border-none font-popinsMedium tracking-wider !important;
  }
  .danger-btn {
    @apply bg-[#FB2E2E]  hover:bg-[#FB2E2E]/90 border-none font-popinsMedium tracking-wider !important;
  }
  .gray-button {
    @apply bg-gray text-sm hover:bg-gray/90 border-none radius font-popinsMedium tracking-wider !important;
  }
  .default-button {
    @apply text-black bg-transparent radius font-popinsMedium tracking-wider !important;
  }
  .outline-button {
    @apply text-secondary transition ease-in-out  duration-500 bg-transparent border border-gray /* hover:bg-secondary   hover:text-white hover:border-transparent */ rounded-[10] font-popinsSemiBold tracking-wider !important;
  }
  .outline-button-border {
    @apply text-[#646464] transition ease-in-out  duration-500 bg-transparent border border-[#646464] /* hover:bg-secondary   hover:text-white hover:border-transparent */ rounded-[10] font-popinsSemiBold tracking-wider !important;
  }
  .outline-button-brand {
    @apply bg-transparent text-brand hover:text-white md:text-sm text-xs border-brand hover:bg-brand/90 radius font-popinsMedium tracking-wider !important;
  }
  .danger-outline-btn {
    @apply bg-transparent  flex-center hover:border-[#FD6868]/90 border border-gray font-popinsMedium tracking-wider !important;
  }
  .edit-outline-btn {
    @apply bg-transparent flex-center hover:border-[#379FFF]/90 border border-gray font-popinsMedium tracking-wider !important;
  }
  .add-outline-btn {
    @apply bg-transparent flex-center hover:border-brand border border-gray font-popinsMedium tracking-wider !important;
  }
  .success-btn {
    @apply bg-successBtn hover:bg-successBtn/90 !important;
  }
  .delete-btn {
    @apply bg-[#FB2E2E] text-white hover:text-white  hover:bg-[#FB2E2E]/90 border-none font-popinsMedium tracking-wider !important;
  }
}

.outline-button {
  transition: all 0.3s ease-in-out;
}

@font-face {
  font-family: "Popins";
  src: local("Popins"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

/* Font - Bold */
@font-face {
  font-family: "Popins-Bold";
  src: local("Popins-Bold"),
    url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}

/* Font - Extra Bold */
@font-face {
  font-family: "Popins-ExtraBold";
  src: local("Popins-ExtraBold"),
    url("./assets/fonts/Poppins-ExtraBold.ttf") format("truetype");
}

/* Font - Italic */
@font-face {
  font-family: "Popins-Italic";
  src: local("Popins-Italic"),
    url("./assets/fonts/Poppins-Italic.ttf") format("truetype");
}

/* Font - Bold Italic */
@font-face {
  font-family: "Popins-BoldItalic";
  src: local("Popins-BoldItalic"),
    url("./assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
}

/* Font - Light */
@font-face {
  font-family: "Popins-Light";
  src: local("Popins-Light"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype");
}

/* Font - Light Italic */
@font-face {
  font-family: "Popins-LightItalic";
  src: local("Popins-LightItalic"),
    url("./assets/fonts/Poppins-LightItalic.ttf") format("truetype");
}

/* Font - Medium */
@font-face {
  font-family: "Popins-Medium";
  src: local("Popins-Medium"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

/* Font - Medium Italic */
@font-face {
  font-family: "Popins-MediumItalic";
  src: local("Popins-MediumItalic"),
    url("./assets/fonts/Poppins-MediumItalic.ttf") format("truetype");
}

/* Font - Semi Bold */
@font-face {
  font-family: "Popins-SemiBold";
  src: local("Popins-SemiBold"),
    url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

/* Font - Semi Bold Italic */
@font-face {
  font-family: "Popins-SemiBoldItalic";
  src: local("Popins-SemiBoldItalic"),
    url("./assets/fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
}

/* Font - Thin */
@font-face {
  font-family: "Popins-Thin";
  src: local("Popins-Thin"),
    url("./assets/fonts/Poppins-Thin.ttf") format("truetype");
}

/* Font - Thin Italic */
@font-face {
  font-family: "Popins-ThinItalic";
  src: local("Popins-ThinItalic"),
    url("./assets/fonts/Poppins-ThinItalic.ttf") format("truetype");
}

/* Font - Extra Light */
@font-face {
  font-family: "Popins-ExtraLight";
  src: local("Popins-ExtraLight"),
    url("./assets/fonts/Poppins-ExtraLight.ttf") format("truetype");
}

/* Font - Extra Light Italic */
@font-face {
  font-family: "Popins-ExtraLightItalic";
  src: local("Popins-ExtraLightItalic"),
    url("./assets/fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
}

/* Font - Black */
@font-face {
  font-family: "Popins-Black";
  src: local("Popins-Black"),
    url("./assets/fonts/Poppins-Black.ttf") format("truetype");
}

/* Font - Black Italic */
@font-face {
  font-family: "Popins-BlackItalic";
  src: local("Popins-BlackItalic"),
    url("./assets/fonts/Poppins-BlackItalic.ttf") format("truetype");
}

/* Global Styles */
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Popins-Medium", sans-serif !important;
}

.emphasized-text {
  font-family: "Popins-Italic", sans-serif; /* Use italic style */
}

/* Add other font-family declarations as needed */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-family: "Poppins", sans-serif !important;
}
