.parent-container {
  @apply flex gap-x-2 items-center justify-start;
  .child-text {
    @apply text-[15px] font-popinsMedium;
  }
}

.invite-btn {
  &:hover,
  &f:focus,
  &:active {
    background-color: rgba(69, 103, 255, 0.8) !important;
  }
}

.custom-bg-linear {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #4567ff 0%, #4567ff 5%) !important;
}

.video-edit {
  max-width: 111%;
  height: 100%;
}

.begin-scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
