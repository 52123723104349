.editable-order {
  .ant-form-item-explain-error {
    display: none !important;
  }
  .ant-input-status-error {
    border: 1px solid #ff4d4f !important;
  }
}

.event-master-menus {
  .ant-menu-item:hover {
    color: rgba(0, 0, 0, 0.88) !important;
  }
  .ant-menu-item-selected,
  .ant-menu-item-active {
    color: $primary !important;
    background-color: transparent !important;

    .ant-typography {
      color: $primary !important;
    }
  }

  .ant-menu-item:focus,
  .ant-menu-item:active {
    color: $primary !important;
  }
}

.custom-tabs {
  display: flex;
  justify-content: end;
  // gap: 20px;
  position: relative;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-item {
  position: relative;
  font-size: 16px;
  padding: 0px 16px;
  cursor: pointer;
  border: none;
  background: none;
  height: 100%;
  color: #7c7c7c;
  font-weight: 400;
}

.remove-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  border-radius: 0px;
}

.remove-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.remove-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0px;
}

.remove-scrollbar::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

@media (max-width: 1919px) {
  .tab-item {
    font-size: 14px;
    padding: 0px 12px;
  }
}

@media (max-width: 1800px) {
  .tab-item {
    font-size: 15px !important;
    padding: 0px 12px;
  }
}
@media (max-width: 1700px) {
  .tab-item {
    font-size: 13px !important;
    padding: 0px 10px;
    text-wrap: nowrap;
  }
}

.tab-item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: $primary;
}
.active {
  color: black !important;
}

.tab-item.active::before {
  width: 80%;
  margin: auto;
}

.event-input {
  .ant-form-item .ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: auto;
    max-height: 0 !important;
  }
}

td.ant-table-cell.ant-table-cell-ellipsis {
  // padding: 16px 11px !important;
}

.event-input {
  .ant-input {
    text-indent: 0px;
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .ant-input-affix-wrapper {
    .ant-input {
      padding: 4px 2px !important;
    }
  }

  input#description {
    padding: 4px 2px !important;
  }
  :where(.css-dev-only-do-not-override-17iqx48).ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 10px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Popins;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 5px;
    transition: all 0.2s;
  }
}

.event-input {
  input#description {
    padding: 4px 10px !important;
  }
}

.input-height {
  .ant-input {
    border: 1px solid #dddae9;
    color: black;
    min-height: 25px !important;
    box-shadow: none !important;
    border-radius: 10px;
  }
  .ant-input-affix-wrapper {
    border: 1px solid #dddae9;
    color: black;
    min-height: 25px !important;
    height: 33px !important
    ;
    box-shadow: none !important;
    border-radius: 10px;

    .ant-input {
      border: 1px solid #dddae9;
      color: black;
      min-height: 0 !important;
      box-shadow: none !important;
      border-radius: 10px;
    }
  }
}

.segmented-custom {
  background-color: white;
  padding: 0;
  border: 1px solid #dddae9;
  border-radius: 10px;
  margin-right: 10px;
  .segmented-label {
    min-height: 40px !important;
    line-height: 40px !important;
    min-width: 100px;
    text-align: center;
    border-right: 1px solid #dddae9;
    cursor: pointer;
  }
  .segmented-label:last-child {
    border-right: none !important;
  }
  .segmented-label-selected:last-child {
    border-right: none !important;
  }
  .segmented-label:hover {
    background-color: #f4f6fa;
  }
  .segmented-label-selected {
    background-color: #f4f6fa;
    min-height: 40px !important;
    line-height: 40px !important;
    min-width: 100px;
    text-align: center;
    border-right: 1px solid #dddae9;
    cursor: pointer;
  }
  .segmented-label:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .segmented-label:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .segmented-label-selected:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .segmented-label-selected:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.event-detail-card {
  .ant-card-body {
    padding: 10px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.attendee-dragger {
  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-upload-list-item {
    height: 50px !important;
  }
  .ant-upload-list-item-thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-upload-list-item-image {
    height: 30px !important;
    width: 30px !important;
  }
}
.product-container {
  .ant-input-number-input-wrap input {
    color: black !important;
  }

  .ant-input-number {
    width: 40px !important;
    text-align: center;
    border: none !important;
  }

  .ant-btn:not(:disabled) {
    height: 40px;
    border-radius: 5px !important;
    width: 40px;

    &:focus,
    &:active,
    &:hover {
      border-color: rgb(221 218 233) !important;
      color: black !important;
    }
  }
  .ant-btn:disabled {
    &:focus,
    &:active,
    &:hover {
      border-color: rgb(221 218 233) !important;
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}

.main-upload {
  .ant-upload {
    height: 23px !important;
    width: 27px !important;
    padding: 4px 6px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #6883fd;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 6px;

    .ant-upload-select:hover {
      border: none !important;
    }
  }
}

.listtype {
  .ant-btn-dangerous {
    border: none !important;
    background-color: transparent !important;

    &:focus,
    &:hover,
    &:active {
      border: none !important;
    }
  }
}

.common-modal {
  .ant-modal-content {
    background-color: #fbfbff;
    border-radius: 20px;
  }
}

.delete-modal {
  .ant-modal-content {
    background-color: #fbfbff;
    border-radius: 20px;
  }
}

.download-btn {
  border: none !important;
  &:hover {
    border: none !important;
  }
}

.brochure-card {
  background-color: transparent;

  .ant-card-body {
    padding: 15px 19px !important;
  }
  .ant-card-head-title {
    font-weight: 400 !important;
  }
}

#custom-drop {
  color: #7c7c7c !important;
  .ant-btn {
    height: 35px !important;
  }

  .ant-btn-default {
    background-color: #f4f6fa !important;
    border-radius: 5px !important;

    &:active,
    &:focus {
      border: 1px solid #dddae9 !important;
      color: black !important;
    }
  }

  &:hover,
  &:focus {
    .ant-btn-default {
      border-color: #dddae9 !important;
    }
  }
  .ant-dropdown-trigger {
    background-color: #f4f6fa !important;
    color: black;

    &:hover {
      border-color: #dddae9 !important;
    }
    &:focus {
      border-color: #dddae9 !important;
    }
  }
  .ant-btn-compact-first-item {
    border-start-end-radius: 0 !important;
    border-end-end-radius: 0 !important;
    &:hover {
      color: black !important;
    }
  }
  .ant-btn-compact-last-item {
    // border-radius: 5px !important;
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
    &:hover {
      color: black !important;
    }
  }
}

/* Draft status */
.draft-select {
  .ant-select-selector,
  .ant-select-selector:hover {
    border: 1px solid #7c7c7c !important;
    color: #7c7c7c !important;
  }
  .ant-select-arrow {
    color: #7c7c7c !important;
  }
}

/* Pending status */
.pending-select {
  .ant-select-selector,
  .ant-select-selector:hover {
    border: 1px solid #c69619 !important;
    color: #c69619 !important;
  }
  .ant-select-arrow {
    color: #c69619 !important;
  }
}

/* Under Review status */
.under-select {
  .ant-select-selector,
  .ant-select-selector:hover {
    border: 1px solid #0299c8 !important;
    color: #0299c8 !important;
  }
  .ant-select-arrow {
    color: #0299c8 !important;
  }
}

/* Approved status */
.approved-select {
  .ant-select-selector,
  .ant-select-selector:hover {
    border: 1px solid #1555a0 !important;
    color: #1555a0 !important;
  }
  .ant-select-arrow {
    color: #1555a0 !important;
  }
}

/* Rejected status */
.rejected-select {
  .ant-select-selector,
  .ant-select-selector:hover {
    border: 1px solid #dd1b1b !important;
    color: #dd1b1b !important;
  }
  .ant-select-arrow {
    color: #dd1b1b !important;
  }
}

/* In Progress status */
.in-progress-select {
  .ant-select-selector,
  .ant-select-selector:hover {
    border: 1px solid #672be7 !important;
    color: #672be7 !important;
  }
  .ant-select-arrow {
    color: #672be7 !important;
  }
}

/* Completed status */
.completed-select {
  .ant-select-selector,
  .ant-select-selector:hover {
    border: 1px solid #15a02b !important;
    color: #15a02b !important;
  }
  .ant-select-arrow {
    color: #15a02b !important;
  }
}

/* Cancelled status */
.cancelled-select {
  .ant-select-selector,
  .ant-select-selector:hover {
    border: 1px solid #e5810c !important;
    color: #e5810c !important;
  }
  .ant-select-arrow {
    color: #e5810c !important;
  }
}

/* Default status */
.default-select {
  .ant-select-selector,
  .ant-select-selector:hover {
    border: 1px solid gray !important;
    color: gray !important;
  }
  .ant-select-arrow {
    color: gray !important;
  }
}

.social-custom-icon {
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: rgba(0, 0, 0, 0.88);
    background: none !important;
  }
}
