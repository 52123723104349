.ant-card-custom {
  .ant-card .ant-card-body {
    padding: 0px 0px 0px 0px;
    border-radius: 0 0 6px 6px;
  }
}

.borderless {
  border: none !important;
  background: transparent !important;

  .ant-select {
    .ant-select-selector {
      border: none !important;
    }
  }

  .ant-input-number {
    border: none !important;
  }
}

.remove-bg-dot .ant-timeline-item-head {
  background-color: transparent !important; /* Remove background color */
  border: none !important; /* Remove border */
  position: relative;
  width: 34px !important;
  height: 10px !important;
}

.remove-bg-dot .ant-timeline-item-tail {
  background-color: #6883fd4f; /* Set the desired tail color */
}

.bg-transparent {
  .ant-pagination {
    background-color: transparent !important;
    border-radius: 0px !important;
    border: none !important;
    padding: 10px 0;
    box-shadow: 0px 0px 20px 0px rgba(184, 201, 214, 0.2);
  }
}
